import React from "react"
import "../styles/style.css"
import one from "../assets/1x1.png"

export default function Home() {
  return (
    <div class='container'>
        <div id='mug-wrap'>
            <a href="https://ko-fi.com/johnnyexperimental" target='_blank'><img id='mug' src={one}></img></a>
        </div>
        <div id='screen-wrap'>
            <a href="https://medium.com/@JohnnyExperimental" target='_blank'><img id='screen' src={one}></img></a>
        </div>
        <div id='keys-wrap'>
            <a href="https://soundcloud.com/johnnyexperimental" target='_blank'><img id='keys' src={one}></img></a>
        </div>
        <div id='cat-wrap'>
            <a href="https://cataas.com/cat/cute/says/%22Buy%20Me%20a%20Kofi!%22" target='_blank'><img id='cat' src={one}></img></a>
        </div>
        <div id='spotify-wrap'>
            <a href="https://open.spotify.com/artist/0yJyIx912zSFYfDNsQczW0?si=0UEgNP9uQtqcewdbdiGFtg" target='_blank'><img id='spotify' src={one}></img></a>
        </div>
        <div id='youtube-wrap'>
            <a href="https://www.youtube.com/channel/UCFqgClI7vTM7RHxgXTVIqSg" target='_blank'><img id='youtube' src={one}></img></a>
        </div>
        <div id='soundcloud-wrap'>
            <a href="https://soundcloud.com/johnnyexperimental" target='_blank'><img id='soundcloud' src={one}></img></a>
        </div>
        <div id='facebook-wrap'>
            <a href="https://facebook.com/johnnyexperimental" target='_blank'><img id='facebook' src={one}></img></a>
        </div>
    </div>
  );
}